// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index/route.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index/route.tsx");
}
// REMIX HMR END

import { json } from "@remix-run/node";
import videoBg from "../../../public/assets/umd.mp4";
import { cssBundleHref } from "@remix-run/css-bundle";
import css from "public/assets/css/index.css";
import { StickyNavbar } from "~/components/navigation/navbar";
import { getUser } from "~/services/session.server";
import { useLoaderData } from "@remix-run/react";
import Footer from "~/components/footer";
import { Col, Container, Row } from "react-bootstrap";
export const meta = () => {
  return [{
    title: "The Plants Project"
  }, {
    name: "description",
    content: "A CMSC 435 Project"
  }];
};
export const links = () => [...(cssBundleHref ? [{
  rel: "stylesheet",
  href: cssBundleHref
}] : []), {
  rel: "stylesheet",
  href: css
}];
export const loader = async ({
  request
}) => {
  const user = await getUser(request);
  return json({
    user
  });
};
export default function Index() {
  _s();
  const {
    user
  } = useLoaderData();
  return <div className="main">
      <StickyNavbar user={user} />
      <div className="overlay"></div>
      <video src={videoBg} autoPlay loop muted className="hidden md:block" />
      <div className="content">
        <Row>
          <Col>
            <h1 className="text-5xl sm:text-6xl md:text-7xl font-bold mb-3 text-center">
              <span style={{
              color: "#e21833"
            }}>Roots</span> and{" "}
              <span style={{
              color: "#ffd200"
            }}>Routes</span>
            </h1>
          </Col>
        </Row>

        <Container fluid="sm">
          <Row xs={12} md={"auto"} className="mx-2 md:mx-0 mb-2 justify-center">
            <Col xs={12}>
              <a className="link flex justify-center" href="/field-journal">
                <p className="text-xl">My Field Journal</p>
              </a>
            </Col>

            <Col xs={12}>
              <a className="link flex justify-center" href="/map">
                {" "}
                {/*-filters */}
                <p className="text-xl"> Explore Map</p>
              </a>
            </Col>
            <Col xs={12}>
              <a className="link flex justify-center" href="/quiz-mode">
                <p className="text-xl">Quiz Map</p>
              </a>
            </Col>
            <Col xs={12}>
              <a className="link flex justify-center" href="/roots">
                <p className="text-xl">Routes</p>
              </a>
            </Col>
            <Col xs={12}>
              <a className="link flex justify-center" href="/quizhome">
                <p className="text-xl">Quizzes</p>
              </a>
            </Col>
            <Col xs={12}>
              <a className="link flex justify-center" href="/plantOfTheWeek">
                <p className="text-xl">Plant of The Week</p>
              </a>
            </Col>
            <Col xs={12}>
              <a className="link flex justify-center" href="/origins">
                <p className="text-xl">Project Origins</p>
              </a>
            </Col>
            <Col xs={12}>
              {user && <a className="link flex justify-center" href="/treasure-hunt">
                  <p className="text-xl">Treasure Hunt</p>
                </a>}

            </Col>
            <Col xs={12}>
              {user && <a className="link flex justify-center" href="/whereGrowing">
                  <p className="text-xl">Where Growing</p>
                </a>}

            </Col>

            <Col xs={12}>
              {user && user.userLevel == 3 && user.developerId && <a className="link flex justify-center" href="/addImages">
                  <p className="text-xl text-red">Add Images</p>
                </a>}

            </Col>
          </Row>
        </Container>

        {!user && <Container fluid="sm">
            <Row xs={12} md={"auto"} className="mx-2 md:mx-0 row justify-center mb-2">

              <Col xs={6} md={"auto"}>
                <a className="link signup flex justify-center" href="/register">
                  Sign up
                </a>
              </Col>
              <Col xs={6} md={"auto"}>
                <a className="link login flex justify-center" href="/login">
                  Log in
                </a>
              </Col>
            </Row>
          </Container>}

        <Footer />
      </div>
    </div>;
}
_s(Index, "FpjQZylbefWQChk+MjGNfSb2jDo=", false, function () {
  return [useLoaderData];
});
_c = Index;
var _c;
$RefreshReg$(_c, "Index");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;